import React from 'react'

import Firebase from 'firebase'
import config from './config'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

class App extends React.Component {
  constructor (props) {
    super(props)
    Firebase.initializeApp(config)

    this.state = {
      developers: []
    }
  }

  componentDidMount () {
    this.getUserData()
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState !== this.state) {
      this.writeUserData()
    }
  }

  writeUserData = () => {
    Firebase.database()
      .ref('/users')
      .set(this.state)
    console.log('DATA SAVED')
  };

  getUserData = () => {
    let ref = Firebase.database().ref('/users')
    ref.on('value', snapshot => {
      const state = snapshot.val()
      this.setState(state)
    })
  };

  handleSubmit = event => {
    event.preventDefault()
    let name = this.refs.name.value
    let role = this.refs.role.value
    let uid = this.refs.uid.value

    if (uid && name && role) {
      const { developers } = this.state
      const devIndex = developers.findIndex(data => {
        return data.uid === uid
      })
      developers[devIndex].name = name
      developers[devIndex].role = role
      this.setState({ developers })
    } else if (name && role) {
      const uid = new Date().getTime().toString()
      const { developers } = this.state
      developers.push({ uid, name, role })
      this.setState({ developers })
    }

    this.refs.name.value = ''
    this.refs.role.value = ''
    this.refs.uid.value = ''
  };

  removeData = developer => {
    const { developers } = this.state
    const newState = developers.filter(data => {
      return data.uid !== developer.uid
    })
    this.setState({ developers: newState })
  };

  updateData = developer => {
    this.refs.uid.value = developer.uid
    this.refs.name.value = developer.name
    this.refs.role.value = developer.role
  };

  async onTakePhoto (dataUri) {
    this.setState({
      loading: true
    })

    let name = this.refs.name.value
    let street = this.refs.street.value
    let number = this.refs.number.value

    const uid = new Date().getTime().toString()

    const filename = `${name}.png`
    const storageRef = Firebase.storage().ref('/images/' + filename)

    await storageRef.putString(dataUri, 'data_url')

    const photo = await storageRef.getDownloadURL().then(function (downloadURL) {
      return downloadURL
    })

    const { developers } = this.state
    developers.push({ uid, name, street, number, photo })
    this.setState({
      developers,
      loading: false
    })

    this.refs.name.value = ''
    this.refs.number.value = ''
  }

  render () {
    const { developers } = this.state
    return (
      <React.Fragment>
        <style>
          {`
            .react-html5-camera-photo > video, .react-html5-camera-photo > img{
              width: 100%;
            }
          `}
        </style>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <h1 className='mt-5 mb-5'>Daftar Hadir Halal Bihalal RT 05 RW 03 Cisaranten Kulon</h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-xl-8'>
              <div className='row'>
                {developers.map(developer => {
                  const date = new Date(developer.uid)
                  const hours = date.getHours()
                  const minutes = '0' + date.getMinutes()
                  const seconds = '0' + date.getSeconds()
                  // const formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2)
                  return (
                    <div
                      className='col-xl-6'
                      key={developer.uid}
                    >
                      <div className='card mb-3'>
                        <img class='card-img-top' src={developer.photo} alt='Card image cap' />
                        <div className='card-body'>
                          <h5 className='card-title'>{developer.name}</h5>
                          <p className='card-text'>{developer.street} / {developer.number}</p>
                          {
                          //<p className='card-text'>{formattedTime} {developer.uid}</p>
                          }
                          <button
                            onClick={() => this.removeData(developer)}
                            className='btn btn-link'
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  )
                })}
                <a href='#' className='btn btn-block btn-secondary mb-5'>Kembali ke atas</a>
              </div>
            </div>
            <div className='col-xl-4'>
              <form onSubmit={this.handleSubmit}>
                <div className='form'>
                  <input type='hidden' ref='uid' />
                  <div className='form-group'>
                    <label>Name Kepala Keluarga</label>
                    <input
                      type='text'
                      ref='name'
                      className='form-control'
                      placeholder='Name'
                    />
                  </div>
                  <div className='form-group'>
                    <label>Ruas Jalan</label>
                    <select
                      ref='street'
                      className='form-control'
                    >
                      <option>Permata Bumi Raya</option>
                      <option>Permata Bumi</option>
                      <option>Permata Bumi 1</option>
                      <option>Permata Bumi 2</option>
                      <option>Cisaranten Kulon</option>
                      <option>Cisaranten Baru</option>
                    </select>
                  </div>

                  <div className='form-group'>
                    <label>Nomor Rumah</label>
                    <input
                      type='text'
                      ref='number'
                      className='form-control'
                    />
                  </div>
                  <div className='form-group'>
                    <Camera onTakePhoto={(dataUri) => { this.onTakePhoto(dataUri) }} />
                  </div>
                  { this.state.loading
                    ? <p>... mengirimkan data...</p>
                    : <p>Silahkan ambil foto untuk memasukkan data.</p>
                  }
                </div>
                {
                // <button type='submit' className='btn btn-primary'>
                //     Save
                // </button>

                }
              </form>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default App
