const config = {
  apiKey: 'AIzaSyApAcPU8ewVzxyoxyXzXiZ_mEN-bJ_tBCk',
  authDomain: 'salman-b4171.firebaseapp.com',
  databaseURL: 'https://salman-b4171.firebaseio.com',
  storageBucket: 'salman-b4171.appspot.com'

  // apiKey: "XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
  // authDomain: "my-app-a123z.firebaseapp.com",
  // databaseURL: "https://my-app-a123z.firebaseio.com",
  // projectId: "my-app-a123z",
  // storageBucket: "my-app-a123z.appspot.com",
  // messagingSenderId: "123456789012"
}

export default config
